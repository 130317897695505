<template>
    <div class="syslog" >
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>日志审计</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="syslog-page">
            <div class="syslog-opts">
                <el-date-picker
                    v-model="rangeTimes"
                    type="daterange"
                    :default-time="['00:00:00', '23:59:59']"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    align="right"
                    prefix-icon=""
                    @change="searchLog"

                ></el-date-picker>
                <SpaceItem></SpaceItem>
                <el-select v-model="type" placeholder="操作类型" size="200px" @change="searchLog">
                    <el-option v-for="item in ([{value: '', label: '全部'}].concat(LOG_TYPE))" :key="item.value" :label="item.label"
                               :value="item.value"></el-option>
                </el-select>
                <SpaceItem></SpaceItem>
                <el-select
                    v-model="regionId"
                    placeholder="选择资源池"
                    style="margin-right: 10px;"
                    @change="searchLog"
                >
                    <el-option
                        v-for="item in regionList"
                        :value="item.regionId"
                        :label="item.regionName"
                        :key="item.regionId"/>
                </el-select>
            </div>

            <el-table :data="tableData" v-loading="loadingData" style="width: 100%">
                <el-table-column prop="action" label="操作类型" width="380px">
                    <span slot-scope="{ row }">{{ LOG_TYPE.find(i => i.value === row.action)?.label || '-' }}</span>
                </el-table-column>
                <el-table-column prop="regionName" label="资源池">
                    <span slot-scope="{ row }">{{ row.regionName || '-' }}</span>
                </el-table-column>
                <el-table-column prop="content" label="操作内容"></el-table-column>
                <el-table-column prop="createTime" label="操作时间" width="147px">
                    <template slot-scope="{row}">{{ row.createTime && moment(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}</template>
                </el-table-column>
            </el-table>

            <div class="bs-pager">
                <Pager v-model="pager" :sizes="[5, 10, 20, 50, 100]" @currentChange="handleCurrentChange"
                       @sizeChange="handleSizeChange"></Pager>
            </div>
        </div>
    </div>
</template>
<script>
const TYPE_ALL = "";
const TYPE_EXPANSION = "UPGRADE_ORDER";
const TYPE_RENEW = "RENEW_ORDER";
const TYPE_BUCKET_CREATE = "CREATE_BUCKET";
const TYPE_BUCKET_DELETE = "DELETE_BUCKET";
const TYPE_AKSK_CREATE = "CREATE_AKSK";
const TYPE_AKSK_DELETE = "DELETE_AKSK";
const REPLACE_AKSK = "REPLACE_AKSK";
import  moment from 'moment'
import {LOG_TYPE} from "@/utils/constant";

export default {
    name: "SysLog",
    data: () => {
        return {
            LOG_TYPE,
            regionList: [
                {
                    "name": "华北1",
                    "regionId": "123"
                }
            ],
            regionId: '',
            loadingData: false,
            tableData: [],
            typeMap: {
                [TYPE_ALL]: "全部",
                [TYPE_EXPANSION]: "升级",
                [TYPE_RENEW]: "续期",
                [TYPE_BUCKET_CREATE]: "创建桶",
                [TYPE_BUCKET_DELETE]: "删除桶",
                [TYPE_AKSK_CREATE]: "创建AKSK",
                [TYPE_AKSK_DELETE]: "删除AKSK",
                [REPLACE_AKSK]: "更换AKSK",
            },
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
            options: [
                {
                    value: TYPE_ALL,
                    label: "操作类型",
                },
                // {
                //     value: TYPE_EXPANSION,
                //     label: "升级",
                // },
                // {
                //     value: TYPE_RENEW,
                //     label: "续期",
                // },
                {
                    value: TYPE_BUCKET_CREATE,
                    label: "创建桶",
                },
                {
                    value: TYPE_BUCKET_DELETE,
                    label: "删除桶",
                },
                {
                    value: TYPE_AKSK_CREATE,
                    label: "创建AKSK",
                },
                {
                    value: TYPE_AKSK_DELETE,
                    label: "删除AKSK",
                },
            ],
            rangeTimes: [],
            type: "",
        };
    },
    created() {
        this.loadData();
        this.getRegionList();
    },
    methods: {
        moment,
        getRegionList () {
            this.$post('/region/listPurchased', {})
                .then(res => {
                    if (res.code === '200') {
                        this.regionList = [{regionId: '', regionName: '已开通资源池'}].concat(res.data || []);
                    }
                })
        },
        loadData() {
            this.loadingData = true;
            const {
                pager: {current, size},
                rangeTimes,
                type = "",
                regionId
            } = this;
            let startTime = "";
            let endTime = "";
            if (rangeTimes) {
                [startTime = '', endTime = ''] = rangeTimes;
            }
            // let startDate = startTime != "" ? this.formateDate(startTime) : "";
            // let endDate = endTime != "" ? this.formateDate(endTime) : "";
            if (startTime) {
              // startTime = new Date(startTime.replace('-', '/'))
              // endTime = new Date(endTime.replace('-', '/'))
                startTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss')
                endTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
            }

            this.$postJson("/sysLog/page", {
                pageNum: current ,
                pageSize: size ,
                startTime,
                endTime,
                action: type,
                regionId
            }).then((res) => {
                const {
                    data: {records, total},
                } = res;
                this.tableData = [...records];
                this.pager.total = total;
                this.loadingData = false;
            });
        },
        searchLog() {
            this.pager.current = 1;
            this.loadData();
        },
        formateDate(datetime) {
            function addDateZero(num) {
                return num < 10 ? "0" + num : num;
            }

            let d = new Date(datetime);
            let formatDatetime =
                d.getFullYear() +
                "-" +
                addDateZero(d.getMonth() + 1) +
                "-" +
                addDateZero(d.getDate()) +
                " " +
                addDateZero(d.getHours()) +
                ":" +
                addDateZero(d.getMinutes()) +
                ":" +
                addDateZero(d.getSeconds());
            return formatDatetime;
        },
        handleSizeChange(size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.loadData();
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.loadData();
        },
    },
};
</script>
<style lang="scss">
.syslog {
    min-height: calc(100% - 36px);
    margin: 16px 16px 10px;
    padding: 0 20px;
    background: #fff;
    text-align: left;

    .el-select .el-input .el-select__caret {
        line-height: 28px;
    }
}

.syslog .el-breadcrumb__inner {
    font-size: 16px !important;
    color: #031129 !important;
}

.syslog-opts {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 22px 0;
}
</style>
